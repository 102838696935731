var sendMail = (props) => {
    const {mail, name, body, botVerifier} = props;

    if(!botVerifier){
        let formattedBody = `Contatto da: ${name} <BR/>Indirizzo mail: ${mail}<BR/>Messaggio: ${body}`

        /* SmtpJS.com - v3.0.0 */
        let Email = window.Email;

        Email.send({
            SecureToken : "dd6bdba1-1d14-4732-be6f-b9946a2147d0",
            To : 'info@pitstopbrescia.it',
            From : "info@pitstopbrescia.it",
            Subject : "Contattato da sito web",
            Body : formattedBody
        })
        .then(message=>{
            console.log('email inviata');
        });
    }

}

export default sendMail;