import React from "react"
import { 
    Row, 
    Col, 
    Container, 
    Form, 
    Button, 
    FormGroup, 
    Label, 
    Input, 
} from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import sendMail from "../sendMail"
import Swal from 'sweetalert2'
import "../styles/_contacts.scss"

function ValidateEmail(mail)
{
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
        return (true)
    }
return (false)
}

const Contacts = () => {

    const [checked, setChecked] = React.useState(false);
    const [mail, setMail] = React.useState(false);
    const [name, setName] = React.useState(false);
    const [body, setBody] = React.useState(false);
    const [botVerifier, setbotVerifier] = React.useState('');

    const sendContactInfo = () =>{
        if(checked){
            if(mail && name && body ){
                if(ValidateEmail(mail)){
                    sendMail({mail,name,body,botVerifier});
                    Swal.fire({
                        icon: 'success',
                        //title: 'Oops...',
                        text: 'La sua mail è stata inviata',
                        //footer: '<a href>Why do I have this issue?</a>'
                      })
                }
                else
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'La mail inserita non è valida',
                    //footer: '<a href>Why do I have this issue?</a>'
                  })
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Errore di invio, compilare tutti i campi',
                    //footer: '<a href>Why do I have this issue?</a>'
                  })
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: "Per proseguire accettare l'informativa privacy",
                //footer: '<a href>Why do I have this issue?</a>'
              })
        }
    }

    return (
    <Layout pageInfo={{ pageName: "Contatti" }}>
        <SEO title="Contatti" keywords={[`gatsby`, `react`, `bootstrap`]} />
        <Container className="mb-5 text-center bg-light my-5">
            <Row className="mb-5 justify-content-center">
                <Col className="md-6 mb-5">
                    <h1>Contattaci</h1>
                    <Container className="mt-4 mb-1">
                        <Row>
                        <Col>
                            <h5><b>Telefono: </b><a href="tel:030 881001">030 881001</a></h5>
                        </Col>
                        </Row>
                        <Row>
                        <Col >
                            <h5><b>Cellulare: </b><a href="tel:+39 338 4270208">+39 338 4270208</a></h5>
                        </Col>
                        </Row>
                        <Row className="mb-3">
                        <Col>
                            <h5><b>Email: </b><a href="mailto:info@pitstopbrescia.it">info@pitstopbrescia.it</a></h5>
                        </Col>
                        </Row>
                        </Container>
                        <Container className="shadow">
                            <Row className="justify-content-center">
                                    <iframe
                                    title="map"
                                    style={{ border: 1 }}
                                    src="https://maps.google.com/maps?q=Autoriparazioni+pit+stop+villa+carcina+&output=embed"
                                    width="100%"
                                    height="270px"
                                    frameBorder="0"
                                    />
                            
                            </Row>
                        </Container>
                </Col>
                <Col md="6">
                    <h1>Preferisci lasciarci un messaggio?</h1>
                    <Form noValidate autoComplete="off">
                        <FormGroup>
                            <Input type='hidden' onChange={(e) => setbotVerifier(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">Nome</Label>
                            <Input required className="shadow-sm" type="name" name="name" id="Name" onChange={(e) => setName(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleEmail">E-mail</Label>
                            <Input required className="shadow-sm" type="email" name="email" id="Email" onChange={(e) => setMail(e.target.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="exampleText">Il tuo messaggio</Label>
                            <Input required className="shadow-sm" type="textarea" name="text" id="Text" rows="4" onChange={(e) => setBody(e.target.value)} />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                            <Input type="checkbox" name="informativa" onChange={() => setChecked(!checked)} required />{' '}
                            Accetto l'informativa <a href="/privacy">Privacy</a>
                            </Label>
                        </FormGroup>
                        <Button onClick={()=>sendContactInfo()} className="mt-3" style={{ backgroundColor:'#080a7c' }}>Invia</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    </Layout>
    )
}

export default Contacts 